import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom'
import { FaSearch, FaUpload } from "react-icons/fa";
import NavbarComp from '../Navbar/Navbar';

function DoctorPostList() {
    const [role, setRole] = useState(localStorage.getItem("role"))
    const [users, setUser] = useState([]);
    const [err, setErr] = useState(false);
    const [msg, setMsg] = useState("Please wait...");
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        // reomve " "
        let apiType = "";
        let removeRole = role.replace('"', '');
        let removeRole1 = removeRole.replace('"', '');
        setRole(removeRole1)
        if (removeRole1 === "Eye Admin") {
            apiType = "doc_eye";
            localStorage.setItem("doc_type", apiType)
        }
        else if (removeRole1 === "Gyno Admin") {
            apiType = "doc_gyno";
            localStorage.setItem("doc_type", apiType)
        }
        else if (removeRole1 === "MBBS Admin") {

            apiType = "doc_mbbs";
            localStorage.setItem("doc_type", apiType)
        }
        else if (removeRole1 === "Doc Admin") {

            apiType = "doc_extra";
            localStorage.setItem("doc_type", apiType)
        }
        else if (removeRole1 === "Doc1 Admin") {

            apiType = "doc_extra1";
            localStorage.setItem("doc_type", apiType)
        }
        axios.get(process.env.REACT_APP_BASEURL + "showpostusers/" + apiType)

            .then(res => {
                //console.log(res.data)
                if (res.data.success === true) {
                    setUser(res.data.result)
                    setErr(true)
                } else {
                    //alert('i am in no data');
                    setErr(false);
                    setMsg('No data found.');
                }
            })
            .catch(err =>
                setMsg('Problem in fetching data.')
            )
    }, []);

    return (
        <>
            <NavbarComp />
            <div className='searchMargin'>
                {/* Search Bar */}
                <div className='row'>
                    <div className='col-md-5 ms-5 ' >
                        <h2>Showing Post Prescriptions</h2>
                    </div>
                    <div className='offset-md-2 col-md-3 col-sm-2 col-xs-1 me-5' >
                        <div className='input-group'>
                            <input id="search-input" type="search" onChange={event => { setSearchTerm(event.target.value) }} className='form-control col-s' placeholder='Search' />
                            <button id="search-button" type='button' className='btn btn-dark'>
                                <FaSearch />
                            </button>
                        </div>
                    </div>
                </div>

                {/* form table  */}
                <div className="container  mt-5" style={{ "height": "100vh", "width": "auto" }} >
                    <div className='row col-md-12'>
                        <p className='total col-md-6'>Total users: {users.length} </p>
                        <div className='col-md-6'>
                            <span className='col-md-2 doctor_post_pres_done'></span>
                            <span className='col-md-2 '>Post Prescription</span>
                            <span className='col-md-2 color-doc-box2'></span>
                            <span className='col-md-2 '>N/A</span>
                        </div>
                    </div>
                    <table className="table table-bordered text-center table-hover camp_table td ">
                        <thead className="thead teal text-white" style={{ "height": "50px", "position": "sticky", "top": "0" }}>
                            <tr className='text-center'>
                                {/* <th scope="col">S.No</th> */}
                                <th scope="col" >ID</th>
                                <th scope="col">USERNAME</th>
                                <th scope="col">MOBILE</th>
                                <th scope="col">Upload</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                (err === true) ?
                                    users.filter((user) => {
                                        if (searchTerm === "") {
                                            return user
                                        } else if (user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                            user.mobile.toString().includes(searchTerm)) {
                                            return user
                                        }
                                    }).map((user) => {
                                        return (
                                            <tr key={user.id}

                                                className={user.doc_post_status}
                                            >
                                                {/* <th scope="row">{index+1}</th> */}
                                                <td>{user.id}</td>
                                                <td>{user.name}</td>
                                                <td>{user.mobile}</td>
                                                <td>
                                                    {/* <button className="btn btn-group-lg btn btn-info" to={'/doctor-update-user'}><FaUpload /></button> */}
                                                    <Link className="btn btn-group-lg  text-white" style={{ backgroundColor: "#15b3a1", }} to={`/doctor-upload-post/${user.id}/${user.name}`}>  <FaUpload /> </Link>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr><th scope="row" colSpan="8">{msg}</th></tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    )
}
export default DoctorPostList

