import React from 'react'
import NavbarComp from '../Navbar/Navbar'
import LabUserList from './LabUserList'
function LabDash() {
  return (
    <>
    <NavbarComp/>
    <LabUserList/>
    </>
  )
}

export default LabDash