import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import NavbarComp from '../Navbar/Navbar';

function AddUser() {
    let navigate = useNavigate()
    let [loading, setLoading] = useState(false)
    let [campDetail, setCampDetail] = useState({
        information: {
            name: "",
            mobile: "",
            whatsup_mobile: "",
            id_proofe_type: null,
            id_proof_no: "",
            lab: null,
            doc_eye: null,
            doc_mbbs: null,
            doc_extra: null,
            doc_gyno: null,
            doc_extra1: null,
            user_status: "verified"

        }
    })
    // onchange function
    const updateInput = (e) => {
        setCampDetail(
            {
                ...campDetail,
                information: {
                    ...campDetail.information,
                    [e.target.name]: e.target.value
                }
            }
        )
    }
    // submitfunction or post api
    const handleSubmit = (e) => {
        e.preventDefault()

        // console.log(information)
        setLoading(true)
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json",
            },
        };

        let url = process.env.REACT_APP_BASEURL +"insertuser";
        axios
            .post(url, information, config)
            .then(function (response) {
                // console.log(response.data);
                setLoading(false)
                alert('user added succesfull')
                navigate('/camp')
            })
            .catch(function (error) {
                setLoading(false)
                alert('user not added')
                // console.log(error);
            });

    }
    function update(e) {
        if (e.target.checked === true) {

            setCampDetail(
                {
                    ...campDetail,
                    information: {
                        ...campDetail.information,
                        [e.target.name]: 1
                    }
                }
            )
        } else {
            setCampDetail(
                {
                    ...campDetail,
                    information: {
                        ...campDetail.information,
                        [e.target.name]: 0
                    }
                }
            )
        }
    }
    let { information } = campDetail;
    return (
        <React.Fragment>
            <NavbarComp />

            <div className='container container-fluid searchMargin ' >
                <div className='card-body col-md-12'>
                    <form className='container container-fluid mt-5' onSubmit={handleSubmit} >
                        <h1 className="text-md-center pt-3 text-dark">Add User</h1>
                        <br></br>
                        {/* form inputs */}
                        <div className="form-group row mt-3 offset-md-1">
                            <label htmlFor="text" className="col-md-3 col-form-label " >
                                <h6 className='text-left'> Name<span className='text-danger font-weight-bold ' style={{ fontSize: "20px" }}> *</span></h6></label>
                            <div className="col-md-6">
                                <input id="text" type="text"
                                    className="form-control" required="required"
                                    placeholder='Enter User Name' name="name"
                                    value={information.name}
                                    onChange={updateInput} />
                            </div>
                        </div>
                        <div className="form-group row offset-md-1">
                            <label htmlFor="text1" className="col-md-3 col-form-label "
                            ><h6 className='text-left'>Mobile Number<span style={{ color: "red" }}>*</span></h6>
                            </label>
                            <div className="col-md-6">
                                <input
                                    pattern="[0-9]+"
                                    id="text1"
                                    min="5999999999"
                                    max="9999999999"
                                    name="mobile"
                                    type="number"
                                    className="form-control"
                                    required="required"
                                    placeholder="Mobile number" value={information.mobile}
                                    onChange={updateInput} />
                            </div>
                        </div>
                        <div className="form-group row offset-md-1">
                            <label htmlFor="text2" className="col-md-3 col-form-label " >
                                <h6 className="text-left">Whatsapp Number</h6>
                            </label>
                            <div className="col-md-6">
                                <input
                                    pattern="[0-9]+"
                                    id="text2"

                                    name="whatsup_mobile"
                                    min="5999999999"
                                    max="9999999999"
                                    type="number"
                                    className="form-control"
                                    placeholder="whatsapp number (optional)" value={information.whatsup_mobile}
                                    onChange={updateInput} />
                            </div>
                        </div>
                        <div className="form-group row offset-md-1">
                            <label className="col-md-3 col-form-label ">
                                <h6 className="text-left">ID Type</h6>
                            </label>
                            <div className="col-md-6">
                                <select
                                    id="select"
                                    name="id_proofe_type"
                                    type="select"
                                    required="required"
                                    className="custom-select"
                                    onChange={updateInput}>

                                    <option value="">Select ID Type</option>
                                    <option value="N/A">N/A</option>
                                    <option value="Aadhar">Aadhar</option>
                                    <option value="Pan Card">Pan Card</option>
                                    <option value="Voter ID">Voter ID</option>
                                    <option value="Driving License">Driving License</option>
                                </select>
                            </div>
                        </div>
                        <div className="form-group row offset-md-1">
                            <label className="col-md-3 col-form-label">
                                <h6 className="text-left">ID Number</h6>
                            </label>
                            <div className="col-md-6">
                                <input
                                    id="text3"
                                    name="id_proof_no"
                                    placeholder="ID Number (optional)"
                                    type="text"
                                    className="form-control"
                                    onChange={updateInput}
                                    value={information.id_proof_no}
                                    required="required"
                                />


                            </div>
                        </div>

                        {/* checkbox */}
                        <div className="form-group row offset-md-1">
                            <label className="col-md-3 col-form-label">
                                <h6 className='text-left'>Select Option<span style={{ color: "red" }}>*</span></h6>
                            </label>
                            <div className="col-md-6">
                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="lab" id="lab" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                                    <input name="lab" id="lab" type="checkbox" className="custom-control-input" onClick={update} readOnly />


                                    <label htmlFor="lab" className="custom-control-label">Lab-Test</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="doc_eye" id="doc_eye" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                                    <input name="doc_eye" id="doc_eye" type="checkbox" className="custom-control-input" onClick={update} readOnly />

                                    <label htmlFor="doc_eye" className="custom-control-label">Eye-Test</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="doc_gyno" id="doc_gyno" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                                    <input name="doc_gyno" id="doc_gyno" type="checkbox" className="custom-control-input" onClick={update} readOnly />

                                    <label htmlFor="doc_gyno" className="custom-control-label">Gynecologist</label>
                                </div>

                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="doc_mbbs" id="doc_mbbs" type="checkbox" className="custom-control-input" onClick={update} readOnly />

                                    <label htmlFor="doc_mbbs" className="custom-control-label mt-2 ">MBBS</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="doc_extra" id="doc_extra" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                                    <input name="doc_extra" id="doc_extra" type="checkbox" className="custom-control-input" onClick={update} readOnly />

                                    <label htmlFor="doc_extra" className="custom-control-label">Child Specialist</label>
                                </div>
                                <div className="custom-control custom-checkbox custom-control-inline">

                                    <input name="doc_extra1" id="doc_extra1" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                                    <input name="doc_extra1" id="doc_extra1" type="checkbox" className="custom-control-input" onClick={update} readOnly />

                                    <label htmlFor="doc_extra1" className="custom-control-label">Doctor-2</label>
                                </div>
                            </div>
                        </div>
                        {/* submit button */}
                        <div className="form-group row mt-5">
                            <div className="offset-md-4 col-12">
                                {
                                    loading ?
                                        <button
                                            type="submit" name="submit" className="btn btn-success" >&nbsp;&nbsp;&nbsp; Adding...
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" disabled></span>
                                        </button> : <button name="submit" type="submit" className="btn btn-success" to={`/camp`} > Add User</button>
                                }
                                <Link
                                    to={`/camp`}
                                    className="btn btn-group-lg btn btn-dark ms-3">
                                    Back
                                </Link>

                            </div>
                        </div>
                    </form>
                </div>

            </div>

        </React.Fragment>
    );
}
export default AddUser;




