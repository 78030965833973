import React from 'react'
import { Link } from 'react-router-dom'
import { FaList , FaHospitalUser, FaUserPlus  , FaUserMinus  } from "react-icons/fa";

const Sidebar = () => {
    return (
         <div className="col-md-3 col-lg-2 sidebar-offcanvas pl-0" id="sidebar" role="navigation" style={{backgroundColor:"#e9ecef", position:'fixed',height:'100%'}}>
            <ul className="nav flex-column sticky-top pl-0 pt-5 p-3">
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin"}><h5>Camp Admin</h5></Link>
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin/user-list"}><i className="fas fa-user font-weight-bold"></i>&nbsp;&nbsp; Total Users</Link>
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin/verified-user-list"}><FaUserPlus  size={20} />&nbsp;&nbsp; Verified Users</Link>
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin/denied-user-list"}><FaUserMinus  size={20} />&nbsp;&nbsp; Denied Users</Link>
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin/report-list"}><FaList size={20} />&nbsp;&nbsp; Reports</Link>
                <Link className="nav-item mb-2 nav-link text-secondary" to={"/admin/doctor-list"}><FaHospitalUser size={20} />&nbsp;&nbsp; Total Doctors</Link>
            </ul>
       </div>
    )
}
export default Sidebar