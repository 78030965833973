import {useEffect,useState} from 'react';
import NavbarComp from '../Navbar/Navbar'
import Sidebar from './Sidebar';
import axios from 'axios';
import { FaList , FaHospitalUser, FaUserPlus  , FaUserMinus  } from "react-icons/fa";

 
const AdminDash = () => {
const [count, setCount] = useState('')
const [msg, setMsg] = useState("Please wait...");

useEffect(()=>{
    let config = {
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "application/json"
        },
    };

    let url = process.env.REACT_APP_BASEURL + `showcountuser`;
    axios.get(url,config)
        .then(res => { console.log(res.data)
            if (res.data.success === true) {
                setCount(res.data.result)
            } else {
                setMsg('No data found.');
            }
        })
        .catch(err =>
            setMsg('Problem in fetching data.')
        )
}, []);
    
    return (
        <> 
        <NavbarComp/>
           <div className="container-fluid mt-5" id="main">
              <div className="row row-offcanvas row-offcanvas-left">
                 <Sidebar/>
                        <div className="col main pt-5" style={{marginLeft:"40vh", position:"fixed"}}>
                            <div className="row mb-3">
                                <div className="col-xl-3 col-sm-6 py-2"  >
                                    <div className="card bg-success text-white h-100">
                                        <div className="card-body bg-success" style={{backgroundColor:"#57b960"}}>
                                            <div className="rotate">
                                                <i className="fa fa-user fa-4x"></i>
                                            </div>
                                            <h6 className="text-uppercase">Total Users</h6>
                                            <h1 className="display-4">{count.total}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 py-2">
                                    <div className="card text-white bg-danger h-100">
                                        <div className="card-body bg-danger">
                                            <div className="rotate">
                                            <FaUserPlus  size={70} />
                                            </div>
                                            <h6 className="text-uppercase">Verified Users</h6>
                                            <h1 className="display-4">{count.verified}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6 py-2">
                                    <div className="card text-white bg-info h-100">
                                        <div className="card-body bg-info">
                                            <div className="rotate">
                                            <FaUserMinus  size={70} />
                                            </div>
                                            <h6 className="text-uppercase">Denied Users</h6>
                                            <h1 className="display-4">{count.denied}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-6 py-2">
                                    <div className="card text-white bg-warning h-100">
                                        <div className="card-body">
                                            <div className="rotate">
                                            <FaList size={70} />
                                            </div>
                                            <h6 className="text-uppercase">Reports Generated</h6>
                                            <h1 className="display-4">{count.report}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-sm-6 py-2">
                                    <div className="card text-white bg-primary h-100">
                                        <div className="card-body">
                                            <div className="rotate">
                                            <FaHospitalUser size={70} />
                                            </div>
                                            <h6 className="text-uppercase">Total Doctors</h6>
                                            <h1 className="display-4">{count.doctor}</h1>
                                        </div>
                                    </div>
                                 </div>
                              </div>
                            <hr/>
                          </div> 
                     </div>
                 </div>
             </>
          )
       }
export default AdminDash