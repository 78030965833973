import React from 'react'
import { Link } from 'react-router-dom'

function Logout() {
    return (
        <>
            <section className="d-flex justify-content-center align-items-center flex-wrap main ">
                <div className="container text-center">
                    <h2 className="text-secondary">You are successfully logged out </h2>
                    <Link className="btn btn-warning mt-3 btn-lg" to={'/login'}>Login</Link>
                </div>
            </section>

        </>
    )
}

export default Logout