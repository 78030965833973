import React, { useState } from 'react'
import { Navbar } from 'react-bootstrap'
import { Link, useNavigate ,useLocation } from 'react-router-dom'
import "./navbar.css"
import '../../App.css'
import { FaUserPlus } from "react-icons/fa";
import {
       MdOutlineLogout
} from "react-icons/md";


function NavbarComp() {
       
       const navigate = useNavigate()
       const role = localStorage.getItem("role")
       const logout = () => {
              localStorage.clear();
              navigate('/logout')
       }
       const location = useLocation()
       // console.log(location.pathname)
       const test = true
       return (
              <>
                     <div>
                            <Navbar className='menu-bar teal fixed-top '>
                                   <Navbar.Brand className='text-light label'><label>{JSON.parse(role)}</label></Navbar.Brand>
                                   <div className="nav navbar-nav ms-auto">
                                          {/* Button when user is Camp Admin */}
                                          {
                                                 JSON.parse(role) === "Camp Admin" && (

                                                        <Link className="btn btn-light text-dark btn-sm"
                                                               to='/adduser'>
                                                               <FaUserPlus size={20} />
                                                               Add User</Link>)
                                          }
                                          {/* Button when user is Gyno Admin */}
                                          {
                                                 JSON.parse(role) === "Gyno Admin" && location.pathname === "/doctor" ?
                                                        < Link className="btn btn-light text-dark btn-sm"
                                                               to={"/doctor-post"}>Post Prescription</Link> : JSON.parse(role) === "Gyno Admin" && location.pathname !== "/doctor" ?
                                                               < Link className="btn btn-light text-dark btn-sm"
                                                                      to={"/doctor"}>Pre Prescription</Link> : null
                                          }
                                   
                                          {/* Button when user is MBBS Admin */}
                                          {
                                                 JSON.parse(role) === "MBBS Admin" && location.pathname === "/doctor" ?
                                                        < Link className="btn btn-light text-dark btn-sm"
                                                               to={"/doctor-post"}>Post Prescription</Link> : JSON.parse(role) === "MBBS Admin" && location.pathname !== "/doctor" ?
                                                        < Link className="btn btn-light text-dark btn-sm"
                                                               to={"/doctor"}>Pre Prescription</Link> : null
                                          }
                                          {/* Button when user is Doc Admin */}
                                          {
                                                 JSON.parse(role) === "Doc Admin" && location.pathname === "/doctor" ?
                                                        < Link className="btn btn-light text-dark btn-sm"
                                                               to={"/doctor-post"}>Post Prescription</Link> : JSON.parse(role) === "Doc Admin" && location.pathname !== "/doctor" ?
                                                               < Link className="btn btn-light text-dark btn-sm"
                                                                      to={"/doctor"}>Pre Prescription</Link> : null
                                          }
                                          {/* Button when user is Doc1 Admin */}
                                          {
                                                 JSON.parse(role) === "Doc1 Admin" && location.pathname === "/doctor" ?
                                                        < Link className="btn btn-light text-dark btn-sm"
                                                               to={"/doctor-post"}>Post Prescription</Link> : JSON.parse(role) === "Doc1 Admin" && location.pathname !== "/doctor" ?
                                                               < Link className="btn btn-light text-dark btn-sm"
                                                                      to={"/doctor"}>Pre Prescription</Link> : null
                                          }
                                          <button onClick={logout} className="ms-2 btn btn-dark text-light btn-sm " type="submit">
                                                 <MdOutlineLogout size={20} />
                                                 Logout</button>
                                   </div>
                            </Navbar>
                     </div>
              </>
       )
}
export default NavbarComp