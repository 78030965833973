import React from 'react'
import { Link } from 'react-router-dom';

function DeniedMsg() {
  return (
    <div>
      
      <div className="container container-fluid offset-md-2 mt-5">
        <div className="card col-md-10 shadow rounded-0">
          <div className="card-body"></div>
          <div className="alert alert-warning" role="alert">
            <h4 className="alert-heading align-items-center mb-4">Oops..!&nbsp;😥</h4>

            <p className="mb-0">Something went wrong!  Try again..</p>
          </div>
        </div>
        <Link to={`/register`} className="btn btn-group-lg btn btn-warning btn-lg mt-4 ">Retry</Link>
      </div>
     
    </div>
  )
}

export default DeniedMsg;