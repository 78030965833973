import React, { useState, useEffect} from 'react';
import axios from "axios";
import NavbarComp from '../Navbar/Navbar';
import Sidebar from './Sidebar';


function VerifiedUserList(props) {
    const [users, setUser] = useState([]);
    const [err, setErr] = useState(false);
    const [msg, setMsg] = useState("Please wait...");
    const [searchTerm, setSearchTerm] = useState('');
   
    useEffect(() => {
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            },
        };

        let url = process.env.REACT_APP_BASEURL + `showuserlist/verified`;
        axios.get(url,config)
            .then(res => {
                if (res.data.success === true) {
                    setUser(res.data.result)
                    setErr(true)
                } else {
                    setErr(false);
                    setMsg('No data found.');
                }
            })
            .catch(err =>
                setMsg('Problem in fetching data.')
            )
    }, []);

    return (
        <>
        {/* Imported components */}
        <NavbarComp/>
           <div className="container-fluid mt-5" id="main">
              <div className="row row-offcanvas row-offcanvas-left">
                 <Sidebar/>
        
        <div  className="col main pt-5"  style={{marginLeft:"20vh", position:"fixed"}}>
            {/* Search Bar */}
            <div >
                <div className='container col-md-3'>
                    <div className='input-group'>
                        <input id="search-input" type="search" onChange={event => { setSearchTerm(event.target.value) }} className='form-control' placeholder='Search by Name' />
                           <button id="search-button" type='button' className='btn btn-dark'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                </div>

            {/* form table  */}
           
            <div className="container mt-5 " style={{ "overflow": "scroll", "height": "75vh", "width": "auto" , "marginBottom":"50px"}} >
            <p className='total col-6'>Total users: {users.length} </p>
                <span className='col-6'></span>
                <table className="table table-bordered text-center table-hover camp_table ">
                    <thead className="thead tealSec text-white " style={{ "height": "50px", "position": "sticky", "top": "0" }}>
                        <tr className='text-center '>
                            <th scope="col">USERID</th>
                            <th scope="col">USERNAME</th>
                            <th scope="col">MOBILE</th>
                            <th scope="col">User Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            (err === true) ?
                                users.filter((user) => {
                                    if (searchTerm ==="") {
                                        return user
                                    } else if (user.name.toLowerCase().includes(searchTerm.toLowerCase())||
                                    user.mobile.toString().includes(searchTerm)) {
                                        return user
                                    }
                                }).map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.id}</td>
                      <td>{user.name}</td>
                      <td>{user.mobile}</td>
                      <td>{user.user_status}</td>
                    </tr>
                   )
                 }) : <tr><th scope="row" colSpan="8">{msg}</th></tr>
               }
            </tbody>
           </table>
          </div>
         </div>
       </div>
      </div>
    </div>
  </>
 )
}
export default VerifiedUserList