import React from "react";
import { Link } from "react-router-dom";
import './404NotFound.css';
function NotFound() {
const role = localStorage.getItem("role")

  return (
    <React.Fragment>

      <section className="d-flex justify-content-center align-items-center flex-wrap main ">
      <div className="container text-center">
      
          <h1 style={{fontSize:"200px"}}>404</h1>
          <h2 className="text-secondary">Page Not Found</h2>
          {
            role === null &&<Link className="btn btn-warning mt-3 btn-lg" to={'/login'}>Go Back</Link> 
          }
          
      </div>
      </section>
    </React.Fragment>
  );
}
export default NotFound