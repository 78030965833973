import React from 'react'
import { Navigate, Outlet, } from 'react-router-dom'
function PrivateRoutesDoc() {
  if (localStorage.getItem("role") === null) {
    return <Navigate to='/' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Gyno Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Eye Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "MBBS Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Doc Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Doc1 Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Lab Admin") {
    return <Navigate to='/lab' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Super Admin") {
    return <Navigate to='/admin' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Camp Admin") {
    return <Navigate to='/camp' />
  }
  else {
    <Navigate to='404NotFound' />
  }

}
export default PrivateRoutesDoc
