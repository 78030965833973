import React, { useEffect, useState } from 'react'
import NavbarComp from '../Navbar/Navbar'
import spinner2 from '../../Assets/Img/spinner2.gif'
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import { useParams, Link } from 'react-router-dom';
import axios from "axios";


function DoctorUploadPost(props) {
    let [loading, setLoading] = useState(false)
    let [catchErr, setCatchErr] = useState(false)
    let [successMsg, setSuccessMsg] = useState(false)
    let [hasPres, setHasPres] = useState(false)
    let [hasPost, setHasPost] = useState(false)
    let [hasReport, setHasReport] = useState(false)
    let [preLoading, setPreLoading] = useState(false)
    let [postLoading, setPostLoading] = useState(false)
    let [reportLoading, setReportLoading] = useState(false)
    const { userId, userName } = useParams();
    // Form Data
    let [userid] = useState(userId)
    let [flag] = useState(1)
    let [doc_type] = useState(localStorage.getItem("doc_type"))
    let [filePre, setFilePre] = useState('')
    let [filePost, setFilePost] = useState('')
    let [fileReport, setFileReport] = useState('')
    //Pre prescription already on server
    useEffect(() => {
        setPreLoading(true)
        setPostLoading(true)
        setReportLoading(true)

        axios.get(process.env.REACT_APP_BASEURL + `getprescription/${userId}/${localStorage.getItem("doc_type")}`)
            .then(res => {
                const imgPathPre = res.data.result.prescription
                const imgPathPost = res.data.result.postscription
                const report = res.data.result.lab_report
                //Set if it has Pre prescription
                if (res.data.success === true && imgPathPre != null) {
                    console.log(imgPathPre);
                    setHasPres(true)
                    setFilePre(`http://healthcamp.radixforce.com/public/userimages/${imgPathPre}`)
                    setPreLoading(false)

                } else {
                    setHasPres(false)
                    setPreLoading(false)
                }
                //Set if it has Post prescription
                if (res.data.success === true && imgPathPost != null) {
                    setHasPost(true)
                    setFilePost(`http://healthcamp.radixforce.com/public/userimages/${imgPathPost}`)
                    setPostLoading(false)

                } else {
                    setHasPost(false)
                    setPostLoading(false)
                }
                //Set report is uploaded
                if (res.data.success === true && report != null) {
                    setHasReport(true)
                    setFileReport(`http://healthcamp.radixforce.com/public/userimages/${report}`)
                    setReportLoading(false)
                }
                else {
                    setHasReport(false)
                    setReportLoading(false)
                }

            })
            .catch(() => {
                setPreLoading(false)
                setPostLoading(false)
                setReportLoading(false)
                setCatchErr(true)
            }
            )
    }, []);
    // On click handler
    let upload = () => {
        // console.log(userid, flag, doc_type, file);
        const formData = new FormData();
        formData.append('user_id', userid)
        formData.append('flag', flag)
        formData.append('doc_type', doc_type)
        formData.append('ppimage', filePost)

        setLoading(true)
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            }
        }
        let url = process.env.REACT_APP_BASEURL + 'prepupload';
        axios.post(url, formData, config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.success === true) {
                    setLoading(false)
                    setSuccessMsg(true)
                }
                else {
                    setLoading(false)
                    setCatchErr(true)
                }
            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false)
                setCatchErr(true)
            })
    }
    return (
        <React.Fragment>
            <NavbarComp />
            <div className='container-fluid searchMargin '  >
                <h4 className='mb-2 '>Name-: {userName} </h4>
                <div className="row" >
                    {/* Pre Prescription display */}
                    <div className='col-sm'>
                        <h3 className="card-header text-center">Pre-prescription</h3>
                        <div className="border "  >
                            {
                                preLoading === true ? <img src={spinner2} alt="spinner" style={{ maxWidth: "100%", maxHeight: 475 }} /> :
                                    <img src={filePre} alt="Preview " style={{ maxWidth: "100%", maxHeight: 475 }} />
                            }

                        </div>
                    </div>
                    {/* Report display */}
                    <div className='col-sm'>
                        <h3 className="card-header text-center">Lab-Report</h3>
                        <div className="card "  >
                            {
                                reportLoading === true ? <img src={spinner2} alt="spinner" style={{ maxWidth: "100%", maxHeight: 475 }} /> :

                                    <object data={fileReport} type="application/pdf" height="500" >
                                <div className='text-center'>
                                    <p>
                                                Mobile browser dosen't support PDF plugin <br/>
                                                Instead click here 
                                    </p>
                               <a className='btn btn-success text-center' rel="external" href={fileReport}>View file</a>
                                </div>
                            </object>
                            }
                        </div>
                    </div>
                    {/* Post pres display */}
                    <div className='col-sm'>
                        {/* Show error when img upload fails */}
                        {
                            catchErr && (<div className="alert alert-warning text-center" role="alert">
                                Something went wrong , Retry
                            </div>)
                        }
                        {/* Show success message when img uploads */}
                        {
                            successMsg && (<div className="alert alert-success text-center" role="alert">
                                Image uploaded successfully
                            </div>)
                        }
                        <h3 className="card-header text-center">Post-Prescription</h3>
                        <div className="border "  >
                            {
                                postLoading === true ? <img src={spinner2} alt="spinner" style={{ maxWidth: "100%", maxHeight: 475 }} /> :
                                    hasPost === true ? <img src={filePost} alt="Preview " style={{ maxWidth: "100%", maxHeight: 475 }} /> :
                                        filePost !== '' ? <img
                                            src={URL.createObjectURL(filePost)} alt="Preview " style={{ maxWidth: "100%", maxHeight: 475 }}
                                        /> : < BsFileEarmarkRichtextFill className="mt-3 mb-2" size={'auto'} />
                            }
                            <div className="text-center mt-2">
                                <input onChange={(e) => setFilePost(e.target.files[0])} capture type="file" name="image-upload" id="input" accept="image/*" />
                            </div>
                            <div className='text-center mt-3 mb-3'>
                                {
                                    loading ? <button disabled className="btn btn-success " style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>
                                        Uploading...
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                    </button> :
                                        <button onClick={upload} type="submit" className="btn btn-success">Upload</button>
                                }
                                <Link className="btn btn-dark ms-4" to={"/doctor-post"}>Back</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        </React.Fragment>
    )
}
export default DoctorUploadPost