import React from "react";
import spinner2 from "../../Assets/Img/spinner2.gif"


let Spinner=()=>{

    return(
        <>
         <div className="container mt-5">
             <div className="row">
                <div className="col-12">
                  <div className="text-center">
                     <img src={spinner2} className="d-block m-auto" style={{width:"300px"}} alt="loading spinner" ></img>
                         <h4>Please Wait...</h4>
                </div>     
              </div>
           </div>
      </div>

        </>
    )
}
export default Spinner;