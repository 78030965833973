import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarComp from "../Navbar/Navbar";

function LabReportUpload(props) {
  let { state } = useLocation()
  let navigate = useNavigate()
  let [loading, setLoading] = useState(false)

  let[uniqlabid,changeUniqlabid] = useState(0);
  let [formData, setFormData] = useState({
    name: state.isEdit ? state.data.name : "",
    user_id: state.isEdit ? state.data.id : "",
    lab_id: state.isEdit ? state.data.lab_id : "",
    patho_report_status: state.isEdit ? state.data.lab_status : "",
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    // let userUpdate = {
    //   lab_id: formData.lab_id,
    //   name: formData.name,
    //   user_id: formData.user_id,
    //   patho_report_status: formData.patho_report_status,
    //   lab_report: formData.lab_report,
    // }
    // //console.log(userUpdate);
    const formDataUpload = new FormData();
    //console.log(formData);
    formDataUpload.append('user_id', formData.user_id)
    formDataUpload.append('name', formData.name)
    formDataUpload.append('lab_id', formData.lab_id)
    formDataUpload.append('patho_report_status', formData.patho_report_status)
    formData.patho_report_status!= "report_failed" && formDataUpload.append('lab_report', formData.lab_report)
    // console.log(formDataUpload);
    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
      },
    };
    let url = process.env.REACT_APP_BASEURL+"updatelab";
    axios
      .post(url, formDataUpload, config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.success === true) {
          alert("Report Uploaded Successfully")
          setLoading(false)
          navigate("/lab")
        }
        else {
          alert("Report not Uploaded")
          setLoading(false)
        }
      })
      .catch(function (error) {
        //console.log(error);
        setLoading(false)
      });
  }
  function onImgChange(e){
    let images = e.target.files[0];
    setFormData({
      ...formData, [e.target.name]: images
    })
  }
  function onChange(e) {

    if(e.target.name === "lab_id"){
        if(e.target.value.length == "6"){
  
          let userUpdate = {
            lab_id: e.target.value,
            user_id: formData.user_id
          }
          let config = {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-type": "application/json",
            },
          };
          let url = process.env.REACT_APP_BASEURL+"checklabid";
          axios
            .post(url, userUpdate, config)
            .then(function (response) {
              // console.log(response.data);
              if (response.data.success === true) {
                changeUniqlabid(1);
              }else{
                changeUniqlabid(0);
              }
            })
            .catch(function (error) {
              alert(error);
            });
          
        }
      }

    setFormData({
      ...formData, [e.target.name]: e.target.value
    })
  }
  return (
    <React.Fragment>
    {/* {JSON.stringify(formData)} */}
      <NavbarComp/>
      <div>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css" />
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
        <div className="container container-fluid mt-5">
            <div className="card-body">
              <h1 className="text-center text-dark">Upload Lab Report</h1>
              <form className="container container-fluid mt-4 " onSubmit={handleSubmit} >
                <div className="form-group row mt-3 offset-md-2 ">
                  <label className="col-md-3 col-form-label text-left ">
                    <h6>ID</h6>
                  </label>
                  <div className="col-md-6">
                    <input
                      name="user_id"
                      type="number"
                      size="10"
                      className="form-control"
                      required="required"
                      placeholder="Enter Name"
                      defaultValue={formData.user_id}
                      onChange={onChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mt-3 offset-md-2 ">
                  <label className="col-md-3 col-form-label  ">
                    <h6> Name<span style={{ color: "red" }}>*</span></h6>
                  </label>
                  <div className="col-md-6">
                    <input
                      pattern="[a-z A-Z]+"
                      id="text"
                      name="name"
                      type="text"
                      size="10"
                      className="form-control"
                      required="required"
                      placeholder="Enter Name"
                      defaultValue={formData.name}
                      onChange={onChange}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mt-3 offset-md-2 ">
                  <label className="col-md-3 col-form-label  ">
                    <h6> Lab Id<span style={{ color: "red" }}>*</span></h6>
                  </label>
                  <div className="col-md-6">
                    <input
                      id="text"
                      name="lab_id"
                      type="text"
                      size="10"
                      className="form-control"
                      required="required"
                      placeholder="Enter Lab Id"
                      defaultValue={formData.lab_id}
                      onChange={onChange}
                      maxLength="6"
                      minLength="6"
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row offset-md-2">
                  <label className="col-md-3 col-form-label">
                    <h6>User Status<span style={{ color: "red" }}>*</span></h6>
                  </label>
                  <div className="col-md-6">
                    <select
                      id="select"
                      name="patho_report_status"
                      type="select"
                      className="custom-select"
                      required="required"
                      onChange={onChange}
                      value={formData.patho_report_status}>
                       <option>Select Status</option>
                      <option value="report_done">Report Done</option>
                      <option value="report_failed" >Sample Failed</option> 
                    </select>
                  </div>
                </div>
                <div className="form-group row mt-3 offset-md-2 ">
                  <label className="col-md-3 col-form-label text-left"><h6>Upload Report</h6></label>
                  <div className="col-md-6">
                    <input name="lab_report" type="file" className="form-control"  onChange={onImgChange}  />
                  </div>
                </div>
                <div className="form-group text-center mt-3">
                {/* <div className="offset-md-1 col-8"> */}
                  {
                    loading ?
                    <button
                    name="submit" type="submit" className="btn btn-success " to={"/"} >&nbsp;&nbsp;&nbsp; Updating...
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" disabled></span>
                    </button> : <button name="submit" type="submit" className="btn btn-success " to={"/lab"}>
                      Update</button>
                      }
                  <Link to={`/lab`} className="btn btn-group-lg btn btn-dark ms-3">Back</Link>
                </div>
                {/* </div> */}
              </form>
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default LabReportUpload;
