import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Login from './Components/Login/Login';
import NotFound from './Components/404NotFound/404NotFound';
import Logout from './Components/Logout';
import CampDash from './Components/Camp-Operator/CampDash';
import AddUser from './Components/Camp-Operator/AddUser';
import UpdateUser from './Components/Camp-Operator/UserUpdate';
import LabDash from './Components/Lab-Operator/LabDash';
import LabUpdateUser from './Components/Lab-Operator/LabUpdateUser';
import LabReportUpload from './Components/Lab-Operator/LabReportUpload';
import DoctorDash from './Components/Doctor-Operator/DoctorDash';
import Registration from './Components/User-Screen/Registration';
import SuccessMsg from './Components/User-Screen/SuccessMsg';
import DeniedMsg from './Components/User-Screen/DeniedMsg';
import DoctorUploadPres from './Components/Doctor-Operator/DoctorUploadPres';
import PrivateRoutes from './Components/Routes/PrivateRoutes';
import PrivateRoutesCamp from './Components/Routes/PrivateRoutesCamp';
import PrivateRouteLab from './Components/Routes/PrivateRouteLab';
import PrivateRoutesDoc from './Components/Routes/PrivateRouteDoc';
import AdminDash from './Components/Admin/AdminDash';
import ShowUserList from './Components/Admin/ShowUserList';
import VerifiedUserList from './Components/Admin/VerifiedUserList';
import DeniedUsersList from './Components/Admin/DeniedUsersList';
import ReportList from './Components/Admin/ReportList';
import DoctorList from './Components/Admin/DoctorList';
import DoctorPostList from './Components/Doctor-Operator/DoctorPostList';
import DoctorUploadPost from './Components/Doctor-Operator/DoctorUploadPost';



function App() {
  //Getting value from local storage
  // const isLogin = localStorage.getItem("isLogin");
  // const role = localStorage.getItem("role");
  // console.log(role);
  // console.log(isLogin);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path='/' element={<Navigate to={'/login'} />} />
        <Route path="/logout" element={<Logout />} />
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Registration />} />
        <Route path='/success' element={<SuccessMsg />} />
        <Route path='/denied' element={<DeniedMsg />} />

        {/*  routes for Admin Dash */}
        <Route exact path='/admin' element={<PrivateRoutes />}>
          <Route exact path='/admin' element={<AdminDash />} />
        </Route>

        <Route exact path='/admin/user-list' element={<PrivateRoutes />}>
          <Route exact path='/admin/user-list' element={<ShowUserList />} />
        </Route>

        <Route exact path='/admin/verified-user-list' element={<PrivateRoutes />}>
          <Route exact path='/admin/verified-user-list' element={<VerifiedUserList />} />
        </Route>

        <Route exact path='/admin/denied-user-list' element={<PrivateRoutes />}>
          <Route exact path='/admin/denied-user-list' element={<DeniedUsersList />} />
        </Route>

        <Route exact path='/admin/report-list' element={<PrivateRoutes />}>
          <Route exact path='/admin/report-list' element={<ReportList />} />
        </Route>

        <Route exact path='/admin/doctor-list' element={<PrivateRoutes />}>
          <Route exact path='/admin/doctor-list' element={<DoctorList />} />
        </Route>

        {/*  routes for Camp Dash */}
        <Route exact path='/camp' element={<PrivateRoutesCamp />}>
          <Route path='/camp' element={<CampDash />} />
        </Route>
        <Route exact path='/adduser' element={<PrivateRoutesCamp />}>
          <Route path='/adduser' element={<AddUser />} />
        </Route>
        <Route exact path='/update' element={<PrivateRoutesCamp />}>
          <Route path='/update' element={<UpdateUser />} />
        </Route>

        {/*  routes for Lab Dash */}

        <Route exact path='/lab' element={<PrivateRouteLab/>}>
        <Route path='/lab' element={<LabDash />} />
        </Route>
        <Route exact path='/lab-update-user' element={<PrivateRouteLab/>}>
        <Route path='/lab-update-user' element={<LabUpdateUser />} />
        </Route>
        <Route exact path='/lab-user-report' element={<PrivateRouteLab/>}>
        <Route path='/lab-user-report' element={<LabReportUpload/>} />
        </Route>
        
        {/*  routes for Doc Dash */}
        <Route exact path='/doctor' element={<PrivateRoutesDoc />}>
          <Route exact path='/doctor' element={<DoctorDash />} />
        </Route>
        <Route exact path='/doctor-update-user/:userId/:userName' element={<PrivateRoutesDoc />}>
          <Route exact path='/doctor-update-user/:userId/:userName' element={<DoctorUploadPres />} />
        </Route>
        <Route exact path='/doctor-post' element={<PrivateRoutesDoc />}>
          <Route exact path='/doctor-post' element={<DoctorPostList />} />
        </Route>
        <Route exact path='/doctor-upload-post/:userId/:userName' element={<PrivateRoutesDoc />}>
          <Route exact path='/doctor-upload-post/:userId/:userName' element={<DoctorUploadPost />} />
        </Route>


      </Routes>
    </BrowserRouter>
  );
}
export default App;
