import React, { useState } from "react";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarComp from "../Navbar/Navbar";

function UpdateUser() {
  let [loading, setLoading] = useState(false)
  let { state } = useLocation()
  let navigate = useNavigate()
  //console.log(state)
  //console.log(props)
  let [formData, setFormData] = useState({
    id: state.isEdit ? state.data.id : "",
    name: state.isEdit ? state.data.name : "",
    mobile: state.isEdit ? state.data.mobile : null,
    whatsup_mobile: state.isEdit ? state.data.whatsup_mobile : null,
    id_proofe_type: state.isEdit ? state.data.id_proofe_type : "",
    id_proof_no: state.isEdit ? state.data.id_proof_no : null,
    camp_id: state.isEdit ? state.data.camp_id : null,
    doc_extra: state.isEdit ? state.data.doc_extra : null,
    doc_extra1: state.isEdit ? state.data.doc_extra1 : null,
    doc_eye: state.isEdit ? state.data.doc_eye : null,
    doc_gyno: state.isEdit ? state.data.doc_gyno : null,
    doc_mbbs: state.isEdit ? state.data.doc_mbbs : null,
    lab: state.isEdit ? state.data.lab : null
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    
    let userUpdate = {
      id: formData.id,
      name: formData.name,
      mobile: formData.mobile,
      whatsup_mobile: formData.whatsup_mobile,
      id_proofe_type: formData.id_proofe_type,
      id_proof_no: formData.id_proof_no,
      camp_id: formData.camp_id,
      doc_extra: formData.doc_extra,
      doc_extra1: formData.doc_extra1,
      doc_eye: formData.doc_eye,
      doc_gyno: formData.doc_gyno,
      doc_mbbs: formData.doc_mbbs,
      lab: formData.lab,
      user_status:'verified'

    
    }
    let config = {
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json", 
      },
    };
    //  
    let url = "http://healthcamp.radixforce.com/api/updateuser";
    // console.log(userUpdate);
    axios
      .post(url, userUpdate, config)
      .then(function (response) {
        // console.log(response.data);
        if (response.data.success === true) {
          alert("User is updated Succesfully")
        // console.log(response.data);
          setLoading(false)
          navigate("/camp")
                  } else {
          alert("User not updated Please check the details you filled")
          setLoading(false)
        }
      })
      .catch(function (error) {
       
        setLoading(false)
        alert("User not updated Please check the details you filled")
      });
  }




  function handleInput(e) {

    setFormData({
      ...formData, [e.target.name]: e.target.value
    })
  }
  function update(e) {
   
    setFormData({
      ...formData, [e.target.name]: e.target.checked
    })
  }
  return (
    <React.Fragment>
      <NavbarComp />
      <div>
        <div className="container container-fluid mt-5">
          <div className="card-body">
            <h1 className="text-dark text-center p-3 mt-1">User Update</h1>
            <form className="container container-fluid mt-4 " onSubmit={handleSubmit} >
              <div className="form-group row mt-3 offset-md-2 ">
                <label className="col-md-3 col-form-label text-left">
                  <h6>ID</h6>
                </label>
                <div className="col-md-6">
                  <input
                    id="num"
                    name="id"
                    type="number"
                    size="10"
                    className="form-control"
                    required="required"
                    placeholder="Enter Name"
                    value={formData.id}
                    onChange={handleInput}
                    disabled
                  />
                </div>
              </div>
              <div className="form-group row mt-3 offset-md-2 ">
                <label className="col-md-3 col-form-label  text-left ">
                  <h6> Name<span style={{ color: "red" }}>*</span></h6>
                </label>
                <div className="col-md-6">
                  <input
                    pattern="[a-z A-Z]+"
                    id="text"
                    name="name"
                    type="text"
                    size="10"
                    className="form-control"
                    required="required"
                    placeholder="Enter Name"
                    value={formData.name}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="form-group row offset-md-2">
                <label className="col-md-3 col-form-label text-left">
                  <h6>Mobile Number<span style={{ color: "red" }}>*</span></h6>
                </label>
                <div className="col-md-6">
                  <input
                    pattern="[0-9]+"
                    id="text1"
                    min="5999999999"
                    max="9999999999"
                    name="mobile"
                    type="number"
                    className="form-control"
                    required="required"
                    placeholder="Mobile number"
                    value={formData.mobile}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="form-group row offset-md-2">
                <label className="col-md-3 col-form-label text-left">
                  <h6>Whatsapp Number</h6>
                </label>
                <div className="col-md-6">
                  <input
                    pattern="[0-9]+"
                    id="text2"
                    name="whatsup_mobile"
                    min="5999999999"
                    max="9999999999"
                    type="number"
                    className="form-control"
                    // required="required"
                    placeholder="Enter whatsapp number"
                    defaultValue={formData.whatsup_mobile}
                    onChange={handleInput}
                  />
                </div>
              </div>
              <div className="form-group row offset-md-2">
                <label className="col-md-3 col-form-label text-left">
                  <h6>ID Type<span style={{ color: "red" }}>*</span></h6>
                </label>
                <div className="col-md-6">
                  <select
                    id="select"
                    name="id_proofe_type"
                    type="select"
                    className="custom-select"
                    required="required"
                    defaultValue={formData.id_proofe_type}
                    onChange={handleInput}>
                    <option value="">Select ID Type</option>
                     <option defaultValue="N/A">N/A</option>
                    <option defaultValue="Aadhar">Aadhar</option>
                    <option defaultValue="Pan Card">Pan Card</option>
                    <option defaultValue="Voter ID">Voter ID</option>
                    <option defaultValue="Driving License">Driving License</option>
                  </select>
                </div>
              </div>
              <div className="form-group row offset-md-2">
                <label className="col-md-3 col-form-label text-left">
                  <h6>ID Number<span style={{ color: "red" }}>*</span></h6>
                </label>
                <div className="col-md-6">
                  <input
                    id="text3"
                    name="id_proof_no"
                    placeholder="ID Number"
                    type="text"
                    className="form-control"
                    required="required"
                    defaultValue={formData.id_proof_no}              
                    onChange={handleInput}
                  />
                </div>
              </div>
              {/* .......Check Box.......... */}
              <div className="form-group row offset-md-2">
                <label className="col-md-3 col-form-label text-left">
                  <h6>Select Option<span style={{ color: "red" }}>*</span></h6>
                </label>
                <div className="col-7">
                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.lab == 1 ?
                        <input checked name="lab" id="lab" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="lab" id="lab" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="lab" className="custom-control-label">Lab-Test</label>
                  </div>

                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.doc_eye == 1 ?
                        <input checked name="doc_eye" id="doc_eye" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="doc_eye" id="doc_eye" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="doc_eye" className="custom-control-label">Eye-Test</label>
                  </div>

                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.doc_gyno == 1 ?
                        <input checked name="doc_gyno" id="doc_gyno" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="doc_gyno" id="doc_gyno" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="doc_gyno" className="custom-control-label">Gynecologist</label>
                  </div>

                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.doc_mbbs == 1 ?
                        <input checked name="doc_mbbs" id="doc_mbbs" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="doc_mbbs" id="doc_mbbs" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="doc_mbbs" className="custom-control-label mt-2 ">MBBS</label>
                  </div>

                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.doc_extra == 1 ?
                        <input checked name="doc_extra" id="doc_extra" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="doc_extra" id="doc_extra" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="doc_extra" className="custom-control-label">Child Specilist</label>
                  </div>

                  <div className="custom-control custom-checkbox custom-control-inline">
                    {
                      formData.doc_extra1 == 1 ?
                        <input checked name="doc_extra1" id="doc_extra1" type="checkbox" className="custom-control-input" onClick={update} readOnly /> :
                        <input name="doc_extra1" id="doc_extra1" type="checkbox" className="custom-control-input" onClick={update} readOnly />
                    }
                    <label htmlFor="doc_extra1" className="custom-control-label">Doctor-2</label>
                  </div>
                  
              {/*......... Buttons......... */}
              <div className="form-group mt-3">
                {
                  loading ?
                    <button
                      type="submit" name="submit" className="btn btn-success" >&nbsp;&nbsp;&nbsp; Updating...
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" disabled></span>
                    </button> : <button name="submit" type="submit" className="btn btn-success mt-2"
                      to={"/camp"}
                    >Update </button>
                }&nbsp;&nbsp;
                <Link
                  to={`/camp`}
                  className="btn btn-group-lg btn btn-dark mt-2">
                  Back
                </Link>
              </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
export default UpdateUser;