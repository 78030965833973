import React from 'react'
import NavbarComp from '../Navbar/Navbar'
import UserList from './UserList'

function CampDash() {
  return (
    <>
    <NavbarComp/>
    <UserList/>
    </>
  )
}

export default CampDash