import React, { useState } from "react";
import axios from "axios";
import Captcha from "./Captcha";
import { useNavigate } from "react-router-dom";
import "./registration.css";
import Spinner from "./Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import image1 from "../../Assets/Img/444-01.svg"
function Registration() {
  let navigate = useNavigate()
  let [Registerdetail, setRegisterdetail] = useState({
    loading: false,
    name: "",
    mobile: "",
    whatsup_mobile: " ",
    id_proofe_type: " ",
    id_proof_no: " ",
  });
  // ***********************API Integration*****************
  let handleSubmit = (e) => {
    e.preventDefault();
    setRegisterdetail({
      ...Registerdetail,
      [e.target.name]: e.target.value,
    });
    // console.log(Registerdetail);
    if (e.target[5].value !== e.target[6].value) {
      toast.error("Captcha is Wrong", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
      });
    } else {
      let user = {};
      user["name"] = e.target[0].value;
      user["mobile"] = e.target[1].value;
      user["whatsup_mobile"] = e.target[2].value;
      user["id_proofe_type"] = e.target[3].value;
      user["id_proof_no"] = e.target[4].value;
      user["user_status"] = "registered";
      setRegisterdetail({ loading: true });
      // console.log(user);
      let config = {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "application/json",
        },
      };
      let url = "http://healthcamp.radixforce.com/api/insertuserself";
      axios.post(url, user, config).then((response) => {
        if (response.data.success === true) {
          setRegisterdetail({ loading: false });
          navigate("/success")
        } else {
          navigate("/denied")
        }
      })
        .catch(function (error) {
        });
    }
  };
  let { loading } = Registerdetail;
  return (
    <>
      {loading ? <Spinner></Spinner> :
        <div className="container page-container mb-4 ">
          <div className="row banner-wrapper">
            <div className="d-none d-sm-block col-6 banner">
              <img src={require('../../../src/Assets/Img/poster 1-01.jpg')} />
            </div>
            <div className="col-12 col-sm-6 form" >
              <img src={image1} className="mobile_image" />
              <h1 className="text-center text-secondary">Registration Form</h1>
              <form className="ms-2 me-2 form-group" onSubmit={handleSubmit} >
                <div className="form-group row ">
                  <label className="col-md-4">
                    <h6> Name<span style={{ color: "red", fontWeight: "bold" }}>*</span></h6>
                  </label>
                  <div className="col-md-8">
                    <input
                      pattern="[a-z A-Z '.'']+"
                      id="text"
                      name="name"
                      type="text"
                      size="10"
                      className="form-control"
                      required="required"
                      placeholder="Enter Name / नाम "
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4">
                    <h6>Mobile Number<span style={{ color: "red", fontWeight: "bold" }}>*</span></h6 >
                  </label>
                  <div className="col-md-8">
                    <input
                      id="text1"
                      min="5999999999"
                      max="9999999999"
                      name="mobile"
                      type="number"
                      className="form-control"
                      required="required"
                      placeholder="Mobile number / मोबाइल नंबर"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4">
                    <h6>Whatsapp Number</h6>
                  </label>
                  <div className="col-md-8">
                    <input
                      pattern="[0-9]+"
                      id="text2"
                      name="whatsup_mobile"
                      min="5999999999"
                      max="9999999999"
                      type="number"
                      className="form-control"
                      placeholder="Whatsapp number (optional)/ व्हाट्सएप नंबर "
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4">
                    <h6>ID Type</h6>
                  </label>
                  <div className="col-md-8">
                    <select
                      id="select"
                      name="id_proofe_type"
                      type="select"
                      className="custom-select">
                      <option value="">Select type (optional) / आईडी का प्रकार</option>
                      <option value="Aadhar">Aadhar</option>
                      <option value="Pan Card">Pan Card</option>
                      <option value="Voter ID">Voter ID</option>
                      <option value="Driving License">Driving License</option>
                    </select>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-md-4">
                    <h6>ID Number</h6>
                  </label>
                  <div className="col-md-8">
                    <input
                      id="text3"
                      name="id_proof_no"
                      placeholder="ID Number (optional) / आईडी नंबर"
                      type="text"
                      className="form-control" />
                  </div>
                </div>
                {/* Captcha Code */}
                <div className="form-group row">
                  <label className="col-md-4">
                  </label>
                  <div className="col-md-8 captcha">
                    <div className="cap_wrapper">
                      <Captcha />
                    </div>
                  </div>
                </div>
                <div className="form-group row " >
                  <div className="col-12 successreg " >
                    <button
                      name="submit"
                      type="submit"
                      className="btn btn-success btn-lg text-align-center">
                      Register
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      }
      <ToastContainer />
    </>
  );
};
export default Registration;







