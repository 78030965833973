import React from 'react'
import NavbarComp from '../Navbar/Navbar'
import DoctorUserList from './DoctorUserList'

function DoctorDash() {
  return (
      <>
        <NavbarComp/>
        <DoctorUserList/>
      </>
  )
}

export default DoctorDash