import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { BsFillPencilFill,BsUpload  } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';


function LabUserList(props) {
    const [users, setUser] = useState([]);
    const [err, setErr] = useState(false);
    const [classcolor,changeClasscolor] = useState("");
    const [msg, setMsg] = useState("Please wait...");
    const [searchTerm, setSearchTerm] = useState('');
    const [userstatus, setUserStatus] = useState([]);
    let [text, setText] = useState(['all']);
    let all = useRef(null);
    let other1 = useRef(null);
    let other2 = useRef(null);
    let other3 = useRef(null);
    let other4 = useRef(null);
    let other5 = useRef(null);

    let navigate = useNavigate();

    useEffect(() => {
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            },
        };
        //let url = process.env.REACT_APP_BASEURL + "shownusers/lab";
        let url = process.env.REACT_APP_BASEURL + `showlabusers/all`;
        axios.get(url,config)
            .then(res => {
                if (res.data.success === true) {
                    setUser(res.data.result)
                    setErr(true)
                } else {
                    setErr(false);
                    setMsg('No data found.');
                }
            })
            .catch(err =>
                setMsg('Problem in fetching data.')
            )
    }, []);

    let getData = (e) => {
        if(e.target.name === 'all' && e.target.checked === true){
            other1.current.checked = false;
            other2.current.checked = false;
            other3.current.checked = false;
            other4.current.checked = false;
            other5.current.checked = false;
            text.splice(0, text.length);
            text = ['all'];
        }else{
            if(e.target.checked === true){
                all.current.checked = false;
                text.push(e.target.name);
                let index = text.findIndex(data => data === 'all');
                if(index != -1){
                    text.splice(index, 1);
                }
            }else{
                let index = text.findIndex(data => data === e.target.name);
                if(index != -1){
                    text.splice(index, 1);
                }
            }
        }
        let index = text.findIndex(data => data === 'all');
        if(index != -1){
            text.splice(0, text.length);
            text = ['all'];
        }
        let urlparam = text.toString();
        //console.log(urlparam);
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            },
        };
        let url = process.env.REACT_APP_BASEURL + `showlabusers/${urlparam}`;//console.log(url);
        axios.get(url,config)
            .then(res => {
                if (res.data.success === true) {
                    setUser(res.data.result)
                    setErr(true)
                } else {
                    setErr(false);
                    setMsg('No data found.');
                }
            })
            .catch(err =>
                setMsg('Problem in fetching data.')
            )
    }

    function onEdit(user) {
        //console.log("props", props)
        navigate("/lab-update-user", { state: { data: user, isEdit: true } })
    }
    function onUpload(user) {
        //console.log("props", props)
        navigate("/lab-user-report", { state: { data: user, isEdit: true } })
    }

    return (
        <div>
            {/* Search Bar */}
            <div className='searchMargin'>
                <div className='container col-md-3 me-5 mt-5'>
                    <div className='input-group'>
                        <input id="search-input" type="search" onChange={event => { setSearchTerm(event.target.value) }} className='form-control' placeholder='Search by Name' />
                           <button id="search-button" type='button' className='btn btn-dark'>
                            <i className='fa fa-search'></i>
                        </button>
                    </div>
                </div>

               {/* Input checkbox */}
                <div className = "container mt-5 mb-0">
                     <form>
                        <label className = "checkbox-inline ml-3">
                            <input ref={all} type = "checkbox" 
                              defaultChecked='checked'
                              name = "all"
                              onClick={getData}  /> &nbsp;&nbsp;All
                        </label>
                        <label className = "checkbox-inline ml-3">
                            <input type="checkbox"
                              ref={other1}
                              name = "NA"
                              onClick={getData}/> &nbsp;&nbsp;New Patient
                        </label>
                        <label className = "checkbox-inline ml-3">
                             <input type = "checkbox"
                                ref={other2}
                                name = "sample_collected"
                                onClick={getData} /> &nbsp;&nbsp;Sample Collected
                        </label>
                        <label className = "checkbox-inline ml-3">
                              <input type = "checkbox"
                              ref={other3}
                                name = "sample_denied"
                                onClick={getData} /> &nbsp;&nbsp;Sample Denied
                        </label>
                        <label className = "checkbox-inline ml-3">
                               <input type="checkbox"
                               ref={other4}
                                 name = "report_done"
                                 onClick={getData} /> &nbsp;&nbsp;Report Done
                        </label>
                        <label className = "checkbox-inline ml-3">
                               <input type = "checkbox"
                               ref={other5}
                                 name = "report_failed"
                                 onClick={getData} /> &nbsp;&nbsp;Report Failed
                        </label>
                   </form>
                </div>

            {/* form table  */}
            <div className="container mt-5 " style={{ "overflow": "scroll", "height": "75vh", "width": "auto" , "marginBottom":"50px" }} >
                <p className='total col-6'>Total users: {users.length} </p>
                <span className='col-6'></span>
                <table className="table table-bordered text-center table-hover camp_table ">
                    <thead className="thead tealSec text-white " style={{ "height": "50px", "position": "sticky", "top": "0" }}>
                        <tr className='text-center '>
                            <th scope="col">USERNAME</th>
                            <th scope="col">MOBILE</th>
                            <th scope="col">STATUS</th>
                            <th scope="col">ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                            (err === true) ?
                                users.filter((user) => {
                                    if (searchTerm ==="") {
                                        return user
                                    } else if (user.name.toLowerCase().includes(searchTerm.toLowerCase())||
                                    user.mobile.toString().includes(searchTerm)) {
                                        return user
                                    }
                                }).map((user, index) => {
                  return (
                    <tr key={user.id} className={user.lab_status }>
                      <td>{user.name}</td>
                      <td>{user.mobile}</td>
                      <td>{user.lab_status === "NA" ? 'N/A' : user.lab_status === "sample_collected" ? "Sample collected" : user.lab_status === "sample_denied" ? "Sample denied" : user.lab_status === "report_done" ? "Report done" : "Sample failed" }</td>
                      <td>
                          {
                            user.lab_status === "sample_collected"?
                                <>
                                <button onClick={() => onEdit(user)}
                                    to={`lab-update-user/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3" style={{backgroundColor: "#15b3a1"}}>
                                    <BsFillPencilFill></BsFillPencilFill>
                                </button>
                                <button onClick={() => onUpload(user)}
                                    to={`lab-user-report/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3 mt-1" style={{backgroundColor: "#FFA500"}}>
                                    <BsUpload></BsUpload>
                                </button>
                                </>:
                            user.lab_status === "sample_denied"?
                                <button onClick={() => onEdit(user)}
                                    to={`lab-update-user/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3" style={{backgroundColor: "#15b3a1"}}>
                                    <BsFillPencilFill></BsFillPencilFill>
                                </button>:
                            user.lab_status === "report_done"?
                                <button onClick={() => onUpload(user)}
                                    to={`lab-user-report/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3 mt-1" style={{backgroundColor: "#FFA500"}}>
                                    <BsUpload></BsUpload>
                                </button>:
                            user.lab_status === "report_failed"?
                                <button onClick={() => onUpload(user)}
                                    to={`lab-user-report/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3 mt-1" style={{backgroundColor: "#FFA500"}}>
                                    <BsUpload></BsUpload>
                                </button>:
                                <button onClick={() => onEdit(user)}
                                    to={`lab-update-user/${user.id}`}
                                    className="btn btn-group-lg text-white mr-3" style={{backgroundColor: "#15b3a1"}}>
                                    <BsFillPencilFill></BsFillPencilFill>
                                </button>    
                             }
                        </td>
                    </tr>
                   )
                 }) : <tr><th scope="row" colSpan="8">{msg}</th></tr>
               }
            </tbody>
         </table>
       </div>
     </div>
   </div>
  )
}
export default LabUserList