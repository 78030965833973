import React, { useEffect, useState } from "react";
import axios from "axios";
import { BsFileEarmarkRichtextFill } from "react-icons/bs";
import NavbarComp from "../Navbar/Navbar";
import { Link, useParams } from "react-router-dom";
import spinner2 from '../../Assets/Img/spinner2.gif'

let DoctorUploadPres = () => {
    let [loading, setLoading] = useState(false)
    let [catchErr, setCatchErr] = useState(false)
    let [successMsg, setSuccessMsg] = useState(false)
    let [hasPres, setHasPres] = useState(false)
    let [preLoading, setPreLoading] = useState(false)
    const { userId, userName } = useParams();
    // Form Data
    let [userid] = useState(userId)  
    let [flag] = useState(0)
    let [doc_type] = useState(localStorage.getItem("doc_type"))
    let [file, setFile] = useState('')

    //To show preview if Pre prescription is already on server
    useEffect(() => {
        setPreLoading(true)

        axios.get(process.env.REACT_APP_BASEURL+`getprescription/${userId}/${localStorage.getItem("doc_type")}`)
            .then(res => {
                const imgPath = res.data.result.prescription
                if (res.data.success === true && imgPath != null) {
                        // console.log(imgPath);
                        // let preview = res.data.result.doc_eye_pre;
                        //let preview = `res.data.result.${localStorage.getItem("doc_type")}_pre`
                        setHasPres(true)
                        setFile(`http://healthcamp.radixforce.com/public/userimages/${imgPath}`)
                        setPreLoading(false)
                    
                } else {
                    setHasPres(false)
                    setPreLoading(false)
                }
            })
            .catch(() => {
                setPreLoading(false)
                setCatchErr(true)
            }
            )
    }, []);
    // On click handler
    let upload = () => {
        // console.log(userid, flag, doc_type, file);
        const formData = new FormData();
        formData.append('user_id', userid)
        formData.append('flag', flag)
        formData.append('doc_type', doc_type)
        formData.append('ppimage', file)

        setLoading(true)
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            }
        }
        let url = process.env.REACT_APP_BASEURL +'prepupload';
        axios.post(url, formData, config)
            .then(function (response) {
                // console.log(response.data);
                if (response.data.success === true) {
                    setLoading(false)
                    setSuccessMsg(true)
                }
                else {
                    setLoading(false)
                    setCatchErr(true)
                }
            })
            .catch(function (error) {
                // console.log(error);
                setLoading(false)
                setCatchErr(true)
            })
    }

    return (
        <>
            <NavbarComp />
            <div className="d-flex justify-content-center align-items-center flex-wrap main">
                <div className="container-fluid text-center "  >
                    <h1 className="text-center">Upload Pre-prescription</h1>
                    <p className="text-left">Username : {userName}</p>
                    {/* Show error when img upload fails */}
                    {
                        catchErr && (<div className="alert alert-warning" role="alert">
                            Something went wrong , Retry
                        </div>)
                    }
                    {/* Show success message when img uploads */}
                    {
                        successMsg && (<div className="alert alert-success" role="alert">
                            Image uploaded successfully
                        </div>)
                    }
                    <div className="border" >
                        {
                            preLoading === true ? <img src={spinner2} alt="spinner" style={{ maxWidth: "100%", maxHeight: 250 }} /> :
                                hasPres === true ? <img src={file} alt="Preview " style={{ maxWidth: "100%", maxHeight: 250 }} /> :
                                    file !== '' ? <img
                                        src={URL.createObjectURL(file)} alt="Preview " style={{ maxWidth: "100%", maxHeight: 250 }}
                                    /> : < BsFileEarmarkRichtextFill className="mt-2 mb-2" size={250} />
                        }
                    </div>
                    <div className="text-left">
                        <input onChange={(e) => setFile(e.target.files[0])} capture type="file" name="image-upload" id="input" accept="image/*" />

                    </div>
                    <div className="col mt-3 mt-5">
                        {/* When image is being uploaded spinner */}
                        {
                            loading ? <button disabled className="btn btn-success " style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}>
                                Uploading...
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                            </button> :
                                <button onClick={upload} type="submit" className="btn btn-success">Upload</button>
                        }
                        <Link className="btn btn-dark ms-4" to={"/doctor"}>Back</Link>
                    </div>
                </div>
            </div>

        </>
    );
}
export default DoctorUploadPres;