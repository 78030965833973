import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom'
import { BsFillPencilFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import '../../App.css'


function UserList(props) {
  const [users, setUser] = useState([]);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState("Please wait...");
  const [searchTerm, setSearchTerm] = useState('');

  let navigate = useNavigate();
  //console.log(process.env.REACT_APP_BASEURL);
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASEURL+'shownusers/camp')

      .then(res => {
        //console.log(res.data)
        if (res.data.success === true) {
          setUser(res.data.result)
          setErr(true)
        } else {
          setErr(false);
          setMsg('No data found.');
        }
      })
      .catch(err =>
        setMsg('Problem in fetching data.')
      )
  }, []);

  function onEdit(user) {
    // console.log("props", props)
    navigate("/update", { state: { data: user, isEdit: true } })
  }

  return (
  

    <div className='searchMargin mb-5'>
      {/*...... Search Bar....... */}

      <div className='container col-md-3 me-5 mt-5' >
        <div className='input-group'>
          <input id="search-input" type="search" onChange={event => {setSearchTerm(event.target.value) }} className='form-control' placeholder='Search' />
          <button id="search-button" type='button' className='btn btn-dark'>
           <FaSearch />
          </button>
        </div>
      </div>

      {/*.......... form table........ */}
      <div className="container mt-5" style={{ "height": "100vh", "width": "auto" }} >
      <div className='row col-12'>
        <p className='total col-md-6'>Total users: {users.length} </p>
        <div className='col-md-6'>
          <span className='col-md-2 color-user-box'></span>
            <span className='col-md-2 '>Verified</span>
            <span className='col-md-2 color-user-box2'></span>
            <span className='col-md-2 '>Register</span>
        </div>
        </div>
        <table className="table table-bordered text-center table-hover camp_table td">
          <thead className="thead tealSec text-white " style={{ "height": "50px", "position": "sticky", "top": "0" }}>
            <tr className='text-center'>
              {/* {<th scope="col">S.No</th> } */}
              <th scope="col" >ID</th>
              <th scope="col">USERNAME</th>
              <th scope="col">MOBILE</th>
              <th scope="col">EDIT</th>
            </tr>
          </thead>
          <tbody>
            {/* search with mobile number */}
            {
              (err === true) ? 
                users.filter((user) => {
                  if (searchTerm === "") {
                    return user
                  } else if (user.name.toLowerCase().includes(searchTerm.toLowerCase())||
                  user.mobile.toString().includes(searchTerm))
                  {
                    return user
                  }
                }).map((user) => {
                  return (

                    <tr key={user.id} className={user.user_status}>
                    
                      {/* {<th scope="row">{index+1}</th>} */}
                      <td>{user.id}</td>
                      <td>{user.name}</td> 
                      <td>{user.mobile}</td>
                      <td>
                      {/* edit button */}
                        <button onClick={() => onEdit(user)} to={`/update/${user.id}`} className="btn btn-group-lg text-white" style={{
  backgroundColor: "black"
}}>
                          <BsFillPencilFill></BsFillPencilFill>
                        </button>
                      </td>
                    </tr>
                  )
                }) : <tr><th scope="row" colSpan="8">{msg}</th></tr>
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default UserList
