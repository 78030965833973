import React, { useState} from "react";

function Captcha ()

 {
 const [user, setUser] = useState({
    captchaname: "",
  });

  const characters = "abc123";

  function generateString(length)
   {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  const captcha = generateString(6); // Function called here and save in captcha variable
  let handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    user[name] = value;
    setUser(user);
  };

  return (
    <React.Fragment>
      <span>
        <h4 id="captcha" className="col-12">
          <input type="hidden" name="captchavalue" value={captcha} />
          {captcha}
        </h4>
      </span>

      
        <div className="col-12">
          <input
            type="text"
            id="inputType"
            className="form-control"
          placeholder="Enter Captcha / कैप्चा डाले"
            name="captchaname"
            onChange={handleChange}
            autoComplete="off"
          />
        </div>
       
      
    </React.Fragment>
  );
};
export default Captcha;
