import React from 'react'
import { Navigate, Outlet, } from 'react-router-dom'
function PrivateRoutes() {

  if (localStorage.getItem("role")=== null){
    return <Navigate to='/' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Super Admin" )  {
    return <Outlet /> 
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Lab Admin"){
    return <Navigate to='/lab'/>
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Camp Admin"){
    return <Navigate to='/camp'/>
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Gyno Admin" || "Eye Admin" || "MBBS Admin" || "Doc Admin" || "Doc1 Admin" ){
    return <Navigate to='/doctor'/>
  }
  else{
    <Navigate to='404NotFound'/>
  }

}

export default PrivateRoutes

// export default function PrivateRoute(props) {
//     let naviagte= useNavigate()
//     const isLogin = localStorage.getItem("isLogin");
//     if (isLogin) {
//     // everything is fine, user logged in
//     return <Route {...props} />
//   } else {
//     return naviagte ('/')
//   }
// }