import React from 'react'
import { Link } from 'react-router-dom';

function SuccessMsg() {


  return (
    <div>

      <div className="container container-fluid offset-md-2 mt-5">
        <div className="card col-md-10 shadow rounded-0">
          <div className="card-body"></div>
          <div className="alert alert-success" role="alert">
            <h4 className="alert-heading align-items-center">Congratulations!</h4>
            <p className="mb-0">You have successfully Registered</p>
          </div>
         
        </div>
        <Link to={`/register`} className="btn btn-group-lg btn btn  mt-4 text-white " style={{ backgroundColor: "#15b3a1" }}>
          Back to link
        </Link>
      </div>
    
    </div>

  )
}

export default SuccessMsg;