import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

function PrivateRoutesCamp() {

  if (localStorage.getItem("role") === null) {
    return <Navigate to='/' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Camp Admin") {
    return <Outlet />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Lab Admin") {
    return <Navigate to='/lab' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Super Admin") {
    return <Navigate to='/admin' />
  }
  else if (JSON.parse(localStorage.getItem("role")) === "Gyno Admin" || "Eye Admin" || "MBBS Admin" || "Doc Admin" || "Doc1 Admin") {
    return <Navigate to='/doctor' />
  }
  else {
    <Navigate to='404NotFound' />
  }

}
export default PrivateRoutesCamp
