import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import './Login.css';
import Rxcamp from "../../Assets/Img/Rxcamp.png";


function Login() {
    const navigate = useNavigate()
    // let [loginType, setloginType] = useState("")
    let [loading, setLoading] = useState(false)
    let [catchError, setCatcherror] = useState(false)
    let [error, setEror] = useState(null)
    let [user, setUser] = useState({
        username: "",
        password: "",
    })

    // Method to update form values
    let update = (e) => {
        setUser({
            ...user,
            [e.target.name]: [e.target.value],
        })

    }

    let login = (event) => {
        event.preventDefault();
        let user = {
            username: event.target[0].value,
            password: event.target[1].value,
        };
        setLoading(true)
        let config = {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "application/json"
            }
        }
        let url = process.env.REACT_APP_BASEURL+'login';
        axios.post(url, user, config)
            .then(function (response) {
                // Condition if the credentials are correct

                if (response.data.success === true) {
                    setEror(false);
                    // Destructuring the data 
                    const { name, email, role } = response.data.user;
                    //Setting the data in localstorage
                    localStorage.setItem("name", JSON.stringify(name));
                    localStorage.setItem("email", JSON.stringify(email));
                    localStorage.setItem("role", JSON.stringify(role));
                    localStorage.setItem("token", JSON.stringify(response.data.token));
                    localStorage.setItem("isLogin", JSON.stringify(response.data.success));
                    setLoading(false);
                    // When user is super admin
                    if (role === "Super Admin") {
                        navigate("/admin")
                    }
                    // When user is camp admin
                    else if (role === "Camp Admin") {
                        navigate("/camp")
                    }
                    // When user is doctor admin 
                    else if (role === "Gyno Admin" ) {
                        navigate("/doctor")
                    }
                    // When user is doctor eye admin 
                    else if (role ===  "Eye Admin") {
                        navigate("/doctor")
                    }
                    // When user is doctor MBBS admin 
                    else if (role ===  "MBBS Admin") {
                        navigate("/doctor")
                    }
                    // When user is doctor Doc admin 
                    else if (role ===  "Doc Admin") {
                        navigate("/doctor")
                    }
                    // When user is doctor Doc1 admin 
                    else if (role ===  "Doc1 Admin") {
                        navigate("/doctor")
                    }
                    // When user is super admin
                    else if (role === "Lab Admin") {
                        navigate("/lab")
                    }
                }
                else {
                    setEror(true);
                    setLoading(false)
                }
            })
            .catch(function (error) {

                console.log(error)
                setLoading(false)
                setCatcherror(true)

            })
    }

    return (
        <>
            <section>
                <div className="container-fluid h-custom ">
                    <div className="row d-flex justify-content-center align-items-center mt-5">
                        {/* Image */}
                        <div className="col-md-9 col-lg-6 col-xl-5">
                            <img src={Rxcamp} className="img-fluid" alt="Banner" />
                        </div>

                        <div className="col-md-8 col-lg-6 col-xl-4 offset-xl-1  rounded-3 p-4 " >
                            <div className="d-flex flex-row align-items-center justify-content-center justify-content-lg-start">
                                <h2 className=" fw-normal display-5  ">Login</h2>
                            </div>
                            {/* Using ternary to show catchError alert message or not  */}
                            {(catchError === true) &&
                                <div className="alert alert-warning" role="alert">
                                    Something went wrong , Retry
                                </div>

                            }
                            {/* Using ternary to show error alert message or not  */}
                            {(error === true) ?

                                <div className="alert alert-danger" role="alert">
                                    Invalid Username or Password
                                </div>
                                :
                                null
                            }
                            <div className="divider d-flex align-items-center my-4">
                            </div>
                            {/* Form start */}
                            <form onSubmit={login}>
                                {/* Emmail field */}
                                <div className="form-outline mb-4 mt-3">
                                    <label className="form-label" >User Name</label>
                                    <input onChange={update} type="text" id="username" name="username" required className="form-control form-control-lg " placeholder="Enter a valid username" />
                                </div>
                                {/* Password field */}
                                <div className="form-outline mb-3">
                                    <label className="form-label" >Password</label>
                                    <input onChange={update} type="password" id="password" name="password" required className="form-control form-control-lg" placeholder="Enter password" />
                                </div>

                                {/* =========Not used as of now========= */}
                                {/* Remember me checkbox */}
                                {/* <div className="d-flex justify-content-between align-items-center"> */}
                                { /* Forgot password link */}
                                {/* <a href="#!" className="text-body">Forgot password?</a>
                                </div>  */}
                                {/* =========Not used as of now========= */}

                                <div className="text-center text-lg-start mt-4 mb-3 pt-2">
                                    {/* Using ternary to show Spinner login button */}
                                    {loading ?
                                        <button disabled className="btn btn btn-lg text-white" style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem', backgroundColor: "#21409A" }}>
                                            Logging in...
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                                        </button>

                                        :
                                         <button className="btn btn btn-lg text-white" style={{ paddingLeft: '2.5rem', paddingRight: '2.5rem', backgroundColor: "#21409A" }}>Login</button>
                                         
                                    }

                                </div>
                            </form>
                            {/* From end */}
                        </div>
                    </div>
                </div>

            </section>


        </>
    );


}

export default Login